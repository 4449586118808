<template>
    <div>
        <customerForm :manager_id="manager_id" :customerFormModal.sync="customerFormModal"
                      :onSelected="customerSelected"/>

        <taskForm :manager_id="manager_id" :order_id="orderId" :taskFormModal.sync="taskFormModal"
        />

        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary">
                    <div class="card-header">
                        <h3 class="card-title">{{ trans.get('admin.pages.orders.title.create') }}</h3>

                        <div class="card-tools">
                            <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip"
                                    title="Collapse">
                                <i class="fas fa-minus"></i></button>
                        </div>
                    </div>
                    <div class="card-body">

                        <div v-if="errors.length > 0" class="alert alert-danger alert-dismissible">
                            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
                            <h5><i class="icon fas fa-ban"></i> {{ trans.get('admin.general.text.errors') }}</h5>
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </div>
                        <div class="row" v-if="formEdit">
                            <div class="col-sm-12">
                                <b>{{ trans.get('admin.pages.orders.form.id.title') }}:</b> {{ orderId }}<br><br>
                                <b>{{ trans.get('admin.pages.orders.form.created_at.title') }}:</b> {{ form.created_at }}<br><br>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label" for="customer_id">{{
                                            trans.get('admin.pages.orders.form.customer_id.title')
                                        }}</label>
                                    <div class="col-sm-9">
                                        <vSelect
                                            :filterable="false"
                                            :options="options.customers"
                                            :reduce="item => item.id"
                                            label="name"
                                            v-model="form.customer_id"
                                            @search="onCustomer"
                                        >
                                            <template slot="no-options">
                                                {{ trans.get('admin.general.text.search') }}
                                            </template>
                                        </vSelect>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-1">
                                <a @click="customerFormModal=true" class="btn btn-primary btn-sm float-right">{{
                                        trans.get('admin.general.text.create')
                                    }}</a>
                            </div>

                            <div class="col-sm-4">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="customer_phone">{{
                                            trans.get('admin.pages.orders.form.phone.title')
                                        }}</label>
                                    <div class="col-sm-10">
                                        <b-form-input @change="customerEdit()"
                                                      v-model="form.customer_phone"></b-form-input>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group row">
                                    <label class="col-sm-2 col-form-label" for="customer_email">{{
                                            trans.get('admin.pages.orders.form.email.title')
                                        }}</label>
                                    <div class="col-sm-10">
                                        <b-form-input @change="customerEdit()"
                                                      v-model="form.customer_email"></b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label" for="manager_id">{{
                                            trans.get('admin.pages.orders.form.manager_id.title')
                                        }}</label>
                                    <div class="col-sm-9">
                                        <vSelect
                                            :filterable="false"
                                            :options="options.admins"
                                            :reduce="item => item.id"
                                            label="name"
                                            v-model="form.manager_id"
                                            @search="onAdmin"
                                            @change="orderEdit()"
                                        >
                                            <template slot="no-options">
                                                {{ trans.get('admin.general.text.search') }}
                                            </template>
                                        </vSelect>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="form-group row" v-if="form.manager_email">
                                    <label class="col-sm-2 col-form-label" for="manager_email">{{
                                            trans.get('admin.pages.orders.form.email.title')
                                        }}</label>
                                    <div class="col-sm-10">
                                        <b-form-input v-model="form.manager_email" :disabled="true"></b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-4">
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label" for="manager_id">{{
                                            trans.get('admin.pages.orders.form.price.title')
                                        }}</label>
                                    <div class="col-sm-6">
                                        <b>{{ form.price }}</b>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-4">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label" for="delivery_type">{{
                                            trans.get('admin.pages.orders.form.delivery_type.title')
                                        }}</label>
                                    <div class="col-sm-6">
                                        <b-form-select
                                            v-model="form.delivery_type"
                                            :options="options.delivery_type"
                                            text-field="name"
                                            value-field="value"
                                            @change="changeDeliveryType()"
                                        ></b-form-select>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row">

                            <div class="col-sm-4">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label" for="payment_method">{{
                                            trans.get('admin.pages.orders.form.payment_method.title')
                                        }}</label>
                                    <div class="col-sm-6">
                                        <b-form-select
                                            v-model="form.payment_method"
                                            :options="options.payment_method"
                                            text-field="name"
                                            value-field="value"
                                            @change="changePaymentMethod()"
                                        ></b-form-select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-6" v-if="form.payment_method">
                                <div class="form-group row">
                                    <label class="col-sm-4 col-form-label" for="payment_status">{{
                                            trans.get('admin.pages.orders.form.payment_status.title')
                                        }}</label>
                                    <div class="col-sm-6">
                                        <b-form-select
                                            v-model="form.payment_status"
                                            :options="options.payment_status[form.payment_method]"
                                            text-field="name"
                                            value-field="value"
                                            @change="orderEdit()"
                                        ></b-form-select>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="row"
                             v-if="form.customer_id && ['courier', 'novaposhta', 'taxi'].includes(form.delivery_type)">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label class="col-sm-1 col-form-label" for="customer_address">{{
                                            trans.get('admin.pages.customers.form.address.title')
                                        }}</label>
                                    <div class="col-sm-11">
                                        <b-form-input @change="customerEdit()"
                                                      v-model="form.customer_address"></b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group row">
                                    <label class="col-sm-1 col-form-label" for="notes">{{
                                            trans.get('admin.pages.orders.form.notes.title')
                                        }}</label>
                                    <div class="col-sm-11">
                                        <b-form-input @change="orderEdit()" v-model="form.notes"></b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <b-tabs content-class="mt-3" v-if="formEdit">
                            <b-tab :title="trans.get('admin.pages.orders.text.tasks')" active>
                                <button @click="taskFormModal=true" type="button"
                                        class="btn btn-success float-right mb-3">{{ trans.get('admin.general.text.add') }}
                                </button>
                                <br>
                                <tasksTable :itemsTasks.sync="tasks" :order_id="orderId"/>
                            </b-tab>
                            <b-tab :title="trans.get('admin.pages.orders.text.files')">
                                <filesTable :order_id="orderId"/>
                            </b-tab>
                        </b-tabs>

                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
        </div>
        <div class="row">
            <div class="col-12">

                <button v-if="!formEdit" type="button" class="btn btn-success float-right" @click="orderCreate()">{{
                        trans.get('admin.general.text.add')
                    }}
                </button>
                <a :href="route('admin.vue.order.download.check', orderId)"
                   class="btn btn-success float-right mr-1 ml-1"
                   type="button" v-if="formEdit && ['cash', 'card'].includes(form.payment_method)">{{
                        trans.get('admin.general.text.print')
                    }}</a>
            </div>
        </div>
        <br>
    </div>
</template>

<script>

import customerForm from './СustomerForm.vue'
import taskForm from './TaskForm.vue'
import tasksTable from './TasksTable.vue'
import filesTable from './FilesTable.vue'

export default {
    components: {
        customerForm,
        taskForm,
        tasksTable,
        filesTable,
    },
    props: {
        manager_id: {
            type: Number
        },
        id: {
            type: Number,
            default: 0
        },
    },

    computed: {},

    data() {
        return {
            errors: [],
            form: {
                customer_id: null,
                customer_phone: null,
                customer_email: null,
                customer_address: null,

                logistician_id: null,
                logistician_email: null,

                manager_id: null,
                manager_phone: null,
                manager_email: null,

                price: '0000',
                price_paid: '0000',

                payment_method: null,
                payment_status: 'no_payment_made',
                delivery_type: null,
                notes: null,

                created_at: null,
            },
            options: {
                customers: [],
                admins: [],
                logisticians: [],
                payment_method: [
                    {"value": "cash", "name": this.trans.get('admin.pages.orders.form.payment_method.options.cash')},
                    {"value": "card", "name": this.trans.get('admin.pages.orders.form.payment_method.options.card')},
                    {"value": "fop_account", "name": this.trans.get('admin.pages.orders.form.payment_method.options.fop_account')},
                    {"value": "account_llc", "name": this.trans.get('admin.pages.orders.form.payment_method.options.account_llc')},
                ],
                payment_status: {
                    "cash": [
                        {
                            "value": "no_payment_made",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.cash.no_payment_made')
                        },
                        {
                            "value": "paid",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.cash.paid')
                        },
                    ],
                    "card": [
                        {
                            "value": "no_payment_made",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.card.no_payment_made')
                        },
                        {
                            "value": "payment_expected",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.card.payment_expected')
                        },
                        {
                            "value": "paid",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.card.paid')
                        },
                    ],
                    "fop_account": [
                        {
                            "value": "no_payment_made",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.fop_account.no_payment_made')
                        },
                        {
                            "value": "payment_expected",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.fop_account.payment_expected')
                        },
                        {
                            "value": "paid",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.fop_account.paid')
                        },
                    ],
                    "account_llc": [
                        {
                            "value": "no_payment_made",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.account_llc.no_payment_made')
                        },
                        {
                            "value": "payment_expected",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.account_llc.payment_expected')
                        },
                        {
                            "value": "paid",
                            "name": this.trans.get('admin.pages.orders.form.payment_status.options.account_llc.paid')
                        },
                    ],
                },
                delivery_type: [
                    {"value": "courier", "name": this.trans.get('admin.pages.orders.form.delivery_type.options.courier')},
                    {"value": "novaposhta", "name": this.trans.get('admin.pages.orders.form.delivery_type.options.novaposhta')},
                    {"value": "selfpickup", "name": this.trans.get('admin.pages.orders.form.delivery_type.options.selfpickup')},
                    {"value": "taxi", "name": this.trans.get('admin.pages.orders.form.delivery_type.options.taxi')},
                ],
            },

            orderId: null,
            formEdit: false,
            customerFormModal: false,
            taskFormModal: false,

            tasks: [],

        };
    },
    mounted() {
        this.prepareComponent();
    },

    methods: {
        /**
         * Prepare the component.
         */
        prepareComponent() {
            if (this.id) {
                this.orderId = this.id
                this.getOrder(this.id)
                this.getTasks()
            } else {
                this.form.manager_id = this.manager_id
                this.getAdmin(this.manager_id)
                if (this.logistician_id) {
                    this.getLogistician(this.logistician_id)
                }
            }
        },


        customerSelected(data) {
            this.customerFormModal = false
            this.form.customer_id = data.id
            this.getCustomer(data.id)
        },

        getTasks() {
            axios.get(this.route('admin.vue.task.index'), {
                params: {
                    id: this.orderId,
                }
            })
                .then(response => {
                    this.tasks = response.data.data
                });
        },

        getOrder(id) {
            axios.get(this.route('admin.vue.order.info'), {
                params: {
                    id: id,
                }
            })
                .then(response => {
                    this.orderId = response.data.data.id
                    this.form.customer_id = response.data.data.customer_id
                    this.form.manager_id = response.data.data.manager_id
                    this.form.logistician_id = response.data.data.logistician_id
                    this.form.payment_method = response.data.data.payment_method
                    this.form.payment_status = response.data.data.payment_status
                    this.form.delivery_type = response.data.data.delivery_type
                    this.form.notes = response.data.data.notes
                    this.form.created_at = response.data.data.created_at
                    this.form.price = response.data.data.price
                    this.form.due_date = response.data.data.due_date

                    this.getCustomer(response.data.data.customer_id)
                    this.getAdmin(response.data.data.manager_id)
                    this.getLogistician(response.data.data.logistician_id)

                    this.formEdit = 1
                });

        },
        orderCreate() {
            axios.post(this.route('admin.vue.order.store'), this.form)
                .then(response => {

                    this.formEdit = 1
                    this.form.id = response.data.data.id
                    this.orderId = response.data.data.id
                    this.form.created_at = response.data.data.created_at
                    this.errors = []

                    this.$bvToast.toast(this.trans.get('admin.general.text.success'), {
                        title: response.data.message,
                        variant: 'success',
                        solid: true
                    })

                    location.assign(this.route('admin.pages.orders.edit', this.orderId))
                })
                .catch(error => {
                    let errors = Object.values(error.response.data.errors);
                    errors = errors.flat();
                    this.errors = errors
                });
        },
        orderEdit() {
            axios.post(this.route('admin.vue.order.update'), Object.assign(this.form, {id: this.orderId}))
                .then(response => {
                    this.errors = []

                    this.$bvToast.toast(this.trans.get('admin.general.text.success'), {
                        title: response.data.message,
                        variant: 'success',
                        solid: true
                    })
                })
                .catch(error => {
                    let errors = Object.values(error.response.data.errors);
                    errors = errors.flat();
                    this.errors = errors
                });
        },
        changeDeliveryType() {
            this.orderEdit()

            this.getCustomer(this.form.customer_id)

            if (this.form.delivery_type != 'selfpickup') {
                this.form.logistician_id = 14
                this.getLogistician(14)
            } else {
                this.form.logistician_id = null
            }
            if (this.form.payment_method == 'cash' && this.form.delivery_type == 'courier') {
                if (this.options.payment_status.cash.find(item => item.value == "payment_expected") === undefined) {
                    this.options.payment_status.cash.push({
                        "value": "payment_expected",
                        "name": this.trans.get('admin.pages.orders.form.payment_status.options.cash.payment_expected')
                    })
                }
            } else {
                this.options.payment_status.cash = this.options.payment_status.cash.filter(function (obj) {
                    return obj.value !== 'payment_expected';
                });
                if (this.form.payment_status == 'payment_expected') {
                    this.form.payment_status = 'no_payment_made'
                }
            }

            if (this.form.delivery_type == 'taxi' || this.form.delivery_type == 'novaposhta') {
                this.options.payment_method = this.options.payment_method.filter(function (obj) {
                    return obj.value !== 'cash';
                });
                if (this.form.payment_method == 'cash') {
                    this.form.payment_method = 'card'
                }
            } else {
                if (this.options.payment_method.find(item => item.value == "cash") === undefined) {
                    this.options.payment_method.push({
                        "value": "cash",
                        "name": this.trans.get('admin.pages.orders.form.payment_method.options.cash')
                    })
                }
            }
        },
        changePaymentMethod() {
            this.orderEdit()

            if (this.form.payment_method == 'cash' && this.form.delivery_type == 'courier') {
                if (this.options.payment_status.cash.find(item => item.value == "payment_expected") === undefined) {
                    this.options.payment_status.cash.push({
                        "value": "payment_expected",
                        "name": this.trans.get('admin.pages.orders.form.payment_status.options.cash.payment_expected')
                    })
                }
            } else {
                this.options.payment_status.cash = this.options.payment_status.cash.filter(function (obj) {
                    return obj.value !== 'payment_expected';
                });
                if (this.form.payment_status == 'payment_expected') {
                    this.form.payment_status = 'no_payment_made'
                }
            }
        },

        customerEdit() {
            axios.post(this.route('admin.vue.customer.update'), {
                id: this.form.customer_id,
                address: this.form.customer_address,
                delivery_type: this.form.delivery_type,
                phone: this.form.customer_phone,
                email: this.form.customer_email,
            })
                .then(response => {
                    this.errors = []

                    this.$bvToast.toast(this.trans.get('admin.general.text.success'), {
                        title: response.data.message,
                        variant: 'success',
                        solid: true
                    })
                })
                .catch(error => {
                });
        },

        getCustomer(id) {
            axios.get(this.route('admin.vue.customer.info'), {
                params: {
                    id: id,
                }
            })
                .then(response => {
                    this.form.customer_phone = response.data.data.phone
                    this.form.customer_email = response.data.data.email
                    this.form.customer_address = ''
                    if (response.data.data.delivery_address && response.data.data.delivery_address[this.form.delivery_type]) {
                        this.form.customer_address = response.data.data.delivery_address[this.form.delivery_type]
                    }

                    if (this.options.customers.find(item => item.id == id) === undefined) {
                        this.options.customers.push(response.data.data)
                    }
                });

        },
        onCustomer(search, loading) {
            loading(true);
            this.searchCustomer(loading, search, this);
        },
        searchCustomer: _.debounce((loading, search, vm) => {
            if (search) {
                axios.get(vm.route('admin.vue.customers.search'), {
                    params: {
                        q: search,
                    }
                })
                    .then(response => {
                        vm.options.customers = response.data.data
                        loading(false)
                    });
            }
            loading(false)
        }, 350),

        getAdmin(id) {
            if (id != null) {
                axios.get(this.route('admin.vue.admin.info'), {
                    params: {
                        id: id,
                    }
                })
                    .then(response => {
                        // this.form.manager_phone = response.data.data.phone
                        this.form.manager_email = response.data.data.email
                        if (this.options.admins.find(item => item.id == id) === undefined) {
                            this.options.admins.push(response.data.data)
                        }
                    });
            }
        },
        onAdmin(search, loading) {
            loading(true);
            this.searchAdmin(loading, search, this);
        },
        searchAdmin: _.debounce((loading, search, vm) => {
            if (search) {
                axios.get(vm.route('admin.vue.admins.search'), {
                    params: {
                        q: search,
                    }
                })
                    .then(response => {
                        vm.options.admins = response.data.data
                        loading(false)
                    });
            }
            loading(false)
        }, 350),

        getLogistician(id) {
            if (id == null || id == 0) return;

            axios.get(this.route('admin.vue.admin.info'), {
                params: {
                    id: id,
                }
            })
                .then(response => {
                    // this.form.manager_phone = response.data.data.phone
                    this.form.logistician_email = response.data.data.email
                    if (this.options.logisticians.find(item => item.id == id) === undefined) {
                        this.options.logisticians.push(response.data.data)
                    }
                });

        },
        onLogistician(search, loading) {
            loading(true);
            this.searchLogistician(loading, search, this);
        },
        searchLogistician: _.debounce((loading, search, vm) => {
            if (search) {
                axios.get(this.route('admin.vue.admins.search'), {
                    params: {
                        q: search,
                    }
                })
                    .then(response => {
                        vm.options.logisticians = response.data.data
                        loading(false)
                    });
            }
            loading(false)
        }, 350),

    },


    watch: {
        'form.customer_id': function (val, old) {
            if (val != old && old != null) {
                this.getCustomer(val)
                this.orderEdit()
            }
        },
        'form.manager_id': function (val, old) {
            if (val != old && old != null) {
                this.getAdmin(val)
            }
        },
        'form.logistician_id': function (val, old) {
            if (val != old && old != null) {
                this.getLogistician(val)
            }
        },
        'tasks': function (val, old) {
            if (val != old && old != null) {
                this.getOrder(this.id)
            }
        },
        'taskFormModal': function (val, old) {
            this.getTasks()
        },
    }
}
</script>
