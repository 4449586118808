<template>
    <div>

        <div class="row">

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="department">{{
                            trans.get('admin.pages.tasks..form.departments.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            v-model="form.department"
                            :options="options.departments"
                            text-field="name"
                            value-field="value"
                            @change="changeСhoice()"
                        ></b-form-select>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="choice">{{ trans.get('admin.pages.tasks..form.choice.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            v-model="form.choice"
                            :options="options.departments_options[form.department]"
                            text-field="name"
                            value-field="value"
                            @change="changeСhoice()"
                        ></b-form-select>
                    </div>
                </div>
            </div>

        </div>

        <businessCards :choice="form.choice"
                       ref="businessCards"
                       :department="form.department"
                       :name="form.name"
                       :only_calc="true"
                       :open="(form.department == 0 && form.choice == 0)"
                       :taskFormModal.sync="taskFormModal"
                       :unknown_task="unknown_task"
        />

        <brochures :choice="form.choice"
                   ref="brochures"
                   :department="form.department"
                   :name="form.name"
                   :only_calc="true"
                   :open="(form.department == 0 && form.choice == 1)"
                   :taskFormModal.sync="taskFormModal"
                   :unknown_task="unknown_task"
        />

        <largeFormatPrinting :choice="form.choice"
                             ref="largeFormatPrinting"
                             :department="form.department"
                             :name="form.name"
                             :only_calc="true"
                             :open="(form.department == 1)"
                             :taskFormModal.sync="taskFormModal"
                             :unknown_task="unknown_task"
        />

        <plotterCutting :choice="form.choice"
                        ref="plotterCutting"
                        :department="form.department"
                        :name="form.name"
                        :only_calc="true"
                        :open="(form.department == 0 && form.choice == 2)"
                        :taskFormModal.sync="taskFormModal"
                        :unknown_task="unknown_task"
        />

        <printingContractor :choice="form.choice"
                            ref="printingContractor"
                            :department="form.department"
                            :name.sync="form.name"
                            :only_calc="true"
                            :open="(form.department == 2)"
                            :taskFormModal.sync="taskFormModal"
                            :unknown_task="unknown_task"
        />

        <glyanecPostcard :choice="form.choice"
                         ref="glyanecPostcard"
                         :department="form.department"
                         :name="form.name"
                         :only_calc="true"
                         :open="(form.department == 3 && form.choice == 0)"
                         :taskFormModal.sync="taskFormModal"
                         :unknown_task="unknown_task"
        />

        <glyanecBusinessCard :choice="form.choice"
                             ref="glyanecBusinessCard"
                             :department="form.department"
                             :name="form.name"
                             :only_calc="true"
                             :open="(form.department == 3 && form.choice == 1)"
                             :taskFormModal.sync="taskFormModal"
                             :unknown_task="unknown_task"
        />

        <glyanecEuroBusinessCard :choice="form.choice"
                                 ref="glyanecEuroBusinessCard"
                                 :department="form.department"
                                 :name="form.name"
                                 :only_calc="true"
                                 :open="(form.department == 3 && form.choice == 2)"
                                 :taskFormModal.sync="taskFormModal"
                                 :unknown_task="unknown_task"
        />

        <glyanecCalendars :choice="form.choice"
                          ref="glyanecCalendars"
                          :department="form.department"
                          :name="form.name"
                          :only_calc="true"
                          :open="(form.department == 3 && form.choice == 3)"
                          :taskFormModal.sync="taskFormModal"
                          :unknown_task="unknown_task"
        />

        <glyanecFlyer :choice="form.choice"
                      ref="glyanecFlyer"
                      :department="form.department"
                      :name="form.name"
                      :only_calc="true"
                      :open="(form.department == 3 && form.choice == 4)"
                      :taskFormModal.sync="taskFormModal"
                      :unknown_task="unknown_task"
        />

        <glyanecBooklet :choice="form.choice"
                        ref="glyanecBooklet"
                        :department="form.department"
                        :name="form.name"
                        :only_calc="true"
                        :open="(form.department == 3 && form.choice == 5)"
                        :taskFormModal.sync="taskFormModal"
                        :unknown_task="unknown_task"
        />

        <rhizography :choice="form.choice"
                     ref="rhizography"
                     :department="form.department"
                     :name.sync="form.name"
                     :only_calc="true"
                     :open="(form.department == 4)"
                     :taskFormModal.sync="taskFormModal"
                     :unknown_task="unknown_task"
        />
        <textarea style="position: relative;left:-600px" id="dummy" v-model="dummyText"></textarea>
        <i v-b-tooltip.hover title="Скопіювати" style="position: relative;left: 39%;bottom: 135px;font-size: 25px;color: dodgerblue;cursor: pointer" class="far fa-copy" @click="copyFields()"></i>
    </div>
</template>

<script>
import businessCards from './calculator/BusinessCards.vue'
import brochures from './calculator/Brochures.vue'
import largeFormatPrinting from './calculator/LargeFormatPrinting.vue'
import plotterCutting from './calculator/PlotterCutting.vue'
import printingContractor from './calculator/PrintingContractor.vue'
import glyanecPostcard from './calculator/glyanec/GlyanecPostcard.vue'
import glyanecBusinessCard from './calculator/glyanec/GlyanecBusinessCard.vue'
import glyanecEuroBusinessCard from './calculator/glyanec/GlyanecEuroBusinessCard.vue'
import glyanecCalendars from './calculator/glyanec/GlyanecCalendars.vue'
import glyanecFlyer from './calculator/glyanec/GlyanecFlyer.vue'
import glyanecBooklet from './calculator/glyanec/GlyanecBooklet.vue'
import rhizography from './calculator/Rhizography.vue'

export default {
    name: 'Calc',
    props: {
        taskFormModal: {
            type: Boolean,
            default: false
        },
    },
    components: {
        businessCards,
        brochures,
        largeFormatPrinting,
        plotterCutting,
        printingContractor,
        glyanecPostcard,
        glyanecBusinessCard,
        glyanecEuroBusinessCard,
        glyanecCalendars,
        glyanecFlyer,
        glyanecBooklet,
        rhizography,
    },

    data() {
        return {
            errors: [],
            formRefs: [
                'businessCards',
                'brochures',
                'largeFormatPrinting',
                'plotterCutting',
                'printingContractor',
                'glyanecPostcard',
                'glyanecBusinessCard',
                'glyanecEuroBusinessCard',
                'glyanecCalendars',
                'glyanecFlyer',
                'glyanecBooklet',
                'rhizography',
                'ufPrinting'
            ],
            formNames: [
                {
                    key: 'types_plotter_cutting',
                    title: 'zzzz'
                },
                {
                    key: 'size',
                    title: 'Формат'
                },
                {
                    key: 'paper_type',
                    title: 'Материали',
                    child: {
                        key: 'paper_type_option',
                        title: 'paper_type_option',
                    }
                },
                {
                    key: 'materials_option',
                    title: 'Материали'
                },
                {
                    key: 'color',
                    title: 'Колір'
                },
                {
                    key: 'lamination',
                    title: 'Ламінація'
                },
                {
                    key: 'diameter',
                    title: 'Діаметр'
                },
                {
                    key: 'glyanec_material',
                    title: 'Материали'
                },
                {
                    key: 'additionally',
                    title: 'Додатково',
                    child: {
                        key: 'additionally_option',
                        title: 'additionally_option'
                    }
                },
                {
                    key: 'additionally_large_format',
                    title: 'Додатково',
                    child: {
                        key: 'additionally_large_format_option',
                        title: 'additionally_large_format_option'
                    }
                },
                {
                    key: 'cover',
                    title: 'Обкладинка',
                    child:{
                        0:{
                            key: 'color',
                            title: 'Колір'
                        },
                        1:{
                            key: 'paper_type',
                            title: 'Материали',
                            child: {
                                key: 'paper_type_option',
                                title: 'paper_type_option'
                            }
                        },
                        2:{
                            key: 'additionally',
                            title: 'Додатково',
                            child: {
                                key: 'additionally_option',
                                title: 'additionally_option'
                            }
                        }
                    },
                },
                {
                    key: 'indoor',
                    title: 'Внутрішній блок',
                    child:{
                        0:{
                            key: 'color',
                            title: 'Колір'
                        },
                        1:{
                            key: 'pages',
                            title: 'Сторінок'
                        },
                        2: {
                            key: 'paper_type',
                            title: 'Материали',
                            child: {
                                key: 'paper_type_option',
                                title: 'paper_type_option'
                            }
                        },
                        3:{
                            key: 'additionally',
                            title: 'Додатково',
                            child: {
                                key: 'additionally_option',
                                title: 'additionally_option'
                            }
                        }
                    },
                },
                {
                    key: 'circulation',
                    title: 'Тираж'
                },
                {
                    key: 'number_cuts',
                    title: 'Ліній порізки'
                },
                {
                    key: 'price',
                    title: 'Вартість'
                },
                {
                    key:'types_binding',
                    title:'Сшивка'
                },
                {
                    key: 'album',
                    title: 'Альбомна'
                },
                {
                    key: 'coverActive',
                    title: 'cover'
                }
            ],
            form: {
                department: 0,
                choice: 0,
            },
            options: {
                departments: [],
                departments_options: [],
            },
            unknown_task: false,
            dummyText: null
        };
    },
    methods: {
        getValueText(refObject, formObject, ee = null, index = -1){
            let key = null;
            let value = null;
            let input = null;
            if (ee !== null){
                let formObj = ee+'.'+formObject.key;
                key = formObject.key;
                value = refObject.form[ee][key];
                input = refObject.$refs[formObj];
            }
            else {
                key = formObject.key;
                value = refObject.form[key];
                input = refObject.$refs[key];
            }

            let result = null;
            if (input && Array.isArray(input) && Array.isArray(value)){
                let finds = [];
                if (~index){
                    let inp = input[index];
                    let inpFind = inp.$props.options.find(item => item[inp.$props.valueField] === value[index]);
                    if (inpFind){
                        let text = formObject.child? inpFind[inp.$props.textField]+' ('+this.getValueText(refObject, formObject.child, ee, index): inpFind[inp.$props.textField]+')'
                        finds.push(text)
                    }
                }else{
                    input.forEach((inp, inpIndex) => {
                        let inpFind = inp.$props.options.find(item => item[inp.$props.valueField] === value[inpIndex]);
                        if (inpFind){
                            let text = formObject.child? inpFind[inp.$props.textField]+' ('+this.getValueText(refObject, formObject.child, ee, inpIndex): inpFind[inp.$props.textField]+')'
                            finds.push(text)
                        }
                    })
                }

                result = finds.join(",")
            }else if (input && input.$props && input.$props.options){
                if (Array.isArray(value)){
                    let finds = input.$props.options.filter(item => value.includes(item[input.$props.valueField]));
                    result = finds.map(item => item[input.$props.textField]).join(",")
                }else{
                    let find = value? input.$props.options.find(item => item[input.$props.valueField] === value): input.$props.options[0];
                    result = find? find[input.$props.textField]: value;
                }
                result = formObject.child? result+' '+this.getValueText(refObject, formObject.child, ee, index): result
            }

            return result || value;
        },

        copyFields(){
            let find = null;
            let endData = [];
            this.formRefs.forEach(item => {
                if (this.$refs[item] && this.$refs[item].open && this.$refs[item].form){
                    find = this.$refs[item];
                }
            })

            if (find){
                this.formNames.forEach(item => {
                    if (item.key in find.form && (item.key != 'cover' || item.key != 'indoor')){
                        endData.push(`${item.title}: ${this.getValueText(find, item)}`)
                    }
                    if (item.key in find.form && (item.key == 'cover' || item.key == 'indoor')){
                        let a = Object.entries(item.child);
                        a.map(key_item => {
                            endData.push(`${key_item[1].title}: ${this.getValueText(find, key_item[1],item.key)}`)
                        })
                    }
                })
            }

            this.form.name = this.options.departments_options[this.form.department][this.form.choice].name

            if (this.form.name == 'Брошура'){
                let replaced = endData[12].replace('Сшивка: ',"");
                endData.unshift(this.form.name+', '+ replaced +` (${this.options.departments[this.form.department].name})`)
            }
            else {
                endData.unshift(this.form.name+' '+`(${this.options.departments[this.form.department].name})`)
            }
            let txt = "";
            let retText = '';
            endData.forEach(item => {
                if(item.includes('Додатково')){
                    let replaced = item.replace('Додатково: ',"");
                    if(replaced.trim()){
                        let x = item.split(':')
                        let y = x[1].split(',')
                        txt += x[0]+":\n";
                        y.forEach(item=> {
                            txt += `- \xa0`+item+"\n";
                        })
                    }
                }else if(item.includes('Формат')) {
                    let z = item.split(',')
                    if (endData[0] == 'Плотерная порезка'){
                        let aa = z[0].split(': ');
                        retText += aa[1] + "x" + z[1] + "мм\n";
                        return;
                    }
                    if (endData.indexOf('Альбомна: true') !== -1){
                        let num  = parseInt(z[1]);
                        txt += z[0] + "x" + num*2 + "мм\n";
                    }
                    else if (endData.indexOf('Альбомна: false') !== -1){
                        let aa = z[0].split(': ');
                        let num  = parseInt(aa[1]);
                        txt += aa[0]+': '+aa[1] + "x" + z[1] + `мм в складеному вигляді, в розвороті (${num*2}х${z[1]}мм)\n`;
                    }
                    else {
                        txt += z[0] + "x" + z[1] + "мм\n";
                    }
                }
                else if(item.includes('Материали')) {
                    let replaced = item.replace('Материали: ', "");
                    if (replaced != 0) {
                        txt += replaced + '\n';
                    }
                }
                else if(item.includes('Ламінація')) {
                    let replaced = item.replace('Ламінація: ',"");
                    if (replaced != 0){
                        txt += replaced+'\n';
                    }
                }else if(item.includes('Тираж')) {
                    txt += item + "шт.\n";
                }else if(item.includes('zzzz')) {
                    let replaced = item.replace('zzzz: ','Формат: ');
                    let replaced_null = item.replace('zzzz: ','');
                    if (replaced_null == "Круг"){
                        if (endData[6]){
                            let r = endData[6].replace('Діаметр: ','');
                            txt += replaced+' '+ r+ "мм\n";
                        }
                    }
                    else {
                        txt += replaced+' '+ retText;
                    }
                }else if(item.includes('Внутрішній блок')) {
                    txt += "Внутрішній блок:\n";
                }else if(item.includes('Альбомна')) {
                    txt += "";
                }else if(item.includes('Діаметр')) {
                    txt += "";
                }else if(item.includes('Сшивка')) {
                    txt += "";
                }else if(item.includes('Обкладинка')) {
                    if (endData.indexOf('cover: true') !== -1){
                        delete endData[15];
                        txt += "Обкладинка:\n";
                    }
                    else if (endData.indexOf('cover: false') !== -1){
                        delete endData[2];
                        delete endData[3];
                        delete endData[4];
                        delete endData[5];
                        delete endData[6];
                        delete endData[15];
                    }
                }else if(item.includes('Ліній порізки')) {
                    let z = item.split(': ')
                    let numeric_z = parseInt(z[1]);
                    if (numeric_z > 0){
                        txt += item+"\n";
                    }
                }else if(item.includes('Вартість')) {
                    if(item.includes('null')){
                        txt += "0грн.\n";
                    }else{
                        txt += item+"грн.\n";
                    }
                }else{
                    txt += item+"\n";
                }
            })

            this.copyToClipboard(txt)
        },
        copyToClipboard(text) {
            this.dummyText = text;
            let dummy = document.getElementById('dummy');

            setTimeout(function(){
                dummy.select();
                dummy.focus();
                document.execCommand("copy");
            },1000)
        },
        prepareComponent() {
            this.getSetting('departments')
        },

        getSetting(name) {
            axios.get(this.route('admin.vue.setting.info'), {
                params: {
                    system_name: name,
                }
            })
                .then(response => {
                    this.options.departments = response.data.data.departments
                    this.options.departments_options = response.data.data.departments_options
                });

        },

        changeСhoice() {
            this.form.name = this.options.departments_options[this.form.department][this.form.choice].name
        },
    },
    created() {
        this.prepareComponent()
    },
    watch: {
        'form.department': function (val) {
            this.form.name = this.options.departments_options[val][this.form.choice].name
        },
        'form.choice': function (val) {
            this.form.name = this.options.departments_options[this.form.department][val].name
        },
    }
}
</script>
