<template>
    <div>
        <b-modal ref="create-customer" :visible="customerFormModal" @hide="customerClose()" id="create-customer"
                 hide-footer :title="trans.get('admin.pages.customers.title.create')">
            <div class="d-block text-center">
                <h3>{{ trans.get('admin.pages.customers.title.create') }}</h3>
            </div>
            <div v-if="errors.length > 0" class="alert alert-danger alert-dismissible">
                <h5><i class="icon fas fa-ban"></i> {{ trans.get('admin.general.text.errors') }}</h5>
                <ul>
                    <li v-for="error in errors">{{ error }}</li>
                </ul>
            </div>
            <div class="form-group row">
                <label class="col-sm-3 col-form-label" for="type">{{ trans.get('admin.pages.customers.form.type.title') }}</label>
                <div class="col-sm-9">
                    <vSelect
                        :filterable="false"
                        :options="options.type"
                        :reduce="item => item.value"
                        label="name"
                        v-model="form.type"
                    />
                </div>
            </div>
            <div v-if="form.type == 'fop'">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="name">{{ trans.get('admin.pages.customers.form.name.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.name"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="phone">{{ trans.get('admin.pages.customers.form.phone.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.phone"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="email">{{ trans.get('admin.pages.customers.form.email.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.email"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="address">{{
                            trans.get('admin.pages.customers.form.address.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.address"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="legal_address">{{
                            trans.get('admin.pages.customers.form.legal_address.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.legal_address"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="type_fop">{{
                            trans.get('admin.pages.customers.form.type_fop.title')
                        }}</label>
                    <div class="col-sm-9">
                        <vSelect
                            :filterable="false"
                            :options="options.type_fop"
                            :reduce="item => item.value"
                            label="name"
                            v-model="form.type_fop"
                        />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="ipn">{{ trans.get('admin.pages.customers.form.ipn.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.ipn"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="manager_id">{{
                            trans.get('admin.pages.customers.form.manager_id.title')
                        }}</label>
                    <div class="col-sm-9">
                        <vSelect
                            :filterable="false"
                            :options="options.admins"
                            :reduce="item => item.id"
                            label="name"
                            v-model="form.manager_id"
                            @search="onCustomerAdmin"
                        >
                            <template slot="no-options">
                                {{ trans.get('admin.general.text.search') }}
                            </template>
                        </vSelect>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="notes">{{ trans.get('admin.pages.customers.form.notes.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.notes"></b-form-input>
                    </div>
                </div>
            </div>
            <div v-if="form.type == 'legal_entity'">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="name">{{ trans.get('admin.pages.customers.form.name.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.name"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="legal_name_full">{{
                            trans.get('admin.pages.customers.form.legal_name_full.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.legal_name_full"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="phone">{{ trans.get('admin.pages.customers.form.phone.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.phone"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="email">{{ trans.get('admin.pages.customers.form.email.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.email"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="address">{{
                            trans.get('admin.pages.customers.form.address.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.address"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="legal_address">{{
                            trans.get('admin.pages.customers.form.legal_address.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.legal_address"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="registration_certificate">{{
                            trans.get('admin.pages.customers.form.registration_certificate.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.registration_certificate"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="ipn">{{ trans.get('admin.pages.customers.form.ipn.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.ipn"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="manager_id">{{
                            trans.get('admin.pages.customers.form.manager_id.title')
                        }}</label>
                    <div class="col-sm-9">
                        <vSelect
                            :filterable="false"
                            :options="options.admins"
                            :reduce="item => item.id"
                            label="name"
                            v-model="form.manager_id"
                            @search="onCustomerAdmin"
                        >
                            <template slot="no-options">
                                {{ trans.get('admin.general.text.search') }}
                            </template>
                        </vSelect>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="notes">{{ trans.get('admin.pages.customers.form.notes.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.notes"></b-form-input>
                    </div>
                </div>
            </div>
            <div v-if="form.type == 'individual'">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="name">{{ trans.get('admin.pages.customers.form.fio.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.name"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="phone">{{ trans.get('admin.pages.customers.form.phone.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.phone"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="email">{{ trans.get('admin.pages.customers.form.email.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.email"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="address">{{
                            trans.get('admin.pages.customers.form.address.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.address"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="date_birth">{{
                            trans.get('admin.pages.customers.form.date_birth.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input type="date" v-model="form.date_birth"></b-form-input>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="manager_id">{{
                            trans.get('admin.pages.customers.form.manager_id.title')
                        }}</label>
                    <div class="col-sm-9">
                        <vSelect
                            :filterable="false"
                            :options="options.admins"
                            :reduce="item => item.id"
                            label="name"
                            v-model="form.manager_id"
                            @search="onCustomerAdmin"
                        >
                            <template slot="no-options">
                                {{ trans.get('admin.general.text.search') }}
                            </template>
                        </vSelect>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="notes">{{ trans.get('admin.pages.customers.form.notes.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.notes"></b-form-input>
                    </div>
                </div>
            </div>
            <b-button class="mt-3" variant="outline-danger" @click="customerClose()">{{ trans.get('admin.general.text.back') }}
            </b-button>
            <b-button class="mt-3" variant="outline-success" @click="customerSend()">{{ trans.get('admin.general.text.add') }}
            </b-button>
        </b-modal>


    </div>
</template>

<script>

export default {
    name: 'customerForm',
    props: {
        manager_id: {
            type: Number
        },
        customerFormModal: {
            type: Boolean,
            default: false
        },
        onSelected: {},
    },

    computed: {},

    data() {
        return {
            errors: [],
            form: {
                type: "individual",
                name: null,
                phone: null,
                email: null,
                address: null,
                legal_address: null,
                type_fop: null,
                ipn: null,
                manager_id: this.manager_id,
                notes: null,
                legal_name_full: null,
                registration_certificate: null,
                date_birth: null,
            },
            options: {
                type: [
                    {"value": "individual", "name": this.trans.get('admin.pages.customers.form.type.options.individual')},
                    {"value": "legal_entity", "name": this.trans.get('admin.pages.customers.form.type.options.legal_entity')},
                    {"value": "fop", "name": this.trans.get('admin.pages.customers.form.type.options.fop')},
                ],
                type_fop: [
                    {"value": "1", "name": this.trans.get('admin.pages.customers.form.type_fop.options.1')},
                    {"value": "2", "name": this.trans.get('admin.pages.customers.form.type_fop.options.2')},
                    {"value": "3", "name": this.trans.get('admin.pages.customers.form.type_fop.options.3')},
                    {"value": "4", "name": this.trans.get('admin.pages.customers.form.type_fop.options.4')},
                ],
                admins: []
            },
        };
    },
    mounted() {
        this.prepareComponent();
    },

    methods: {
        prepareComponent() {
            this.getAdmin(this.manager_id)
        },
        customerClose() {
            this.$emit('update:customerFormModal', false)

            this.errors = []
            this.form.type = "individual"
            this.form.name = null
            this.form.phone = null
            this.form.email = null
            this.form.address = null
            this.form.legal_address = null
            this.form.type_fop = null
            this.form.ipn = null
            this.form.manager_id = null
            this.form.notes = null
            this.form.legal_name_full = null
            this.form.registration_certificate = null
            this.form.date_birth = null
        },
        customerSend() {
            axios.post(this.route('admin.vue.customer.store'), this.form)
                .then(response => {
                    this.customerClose()

                    this.onSelected({
                        id: response.data.data.id
                    })

                    this.$bvToast.toast(this.trans.get('admin.general.text.success'), {
                        title: response.data.message,
                        variant: 'success',
                        solid: true
                    })
                })
                .catch(error => {
                    let errors = Object.values(error.response.data.errors);
                    errors = errors.flat();
                    this.errors = errors
                });
        },
        getAdmin(id) {
            if (id != null) {
                axios.get(this.route('admin.vue.admin.info'), {
                    params: {
                        id: id,
                    }
                })
                    .then(response => {
                        if (this.options.admins.find(item => item.id == id) === undefined) {
                            this.options.admins.push(response.data.data)
                        }
                    });
            }
        },

        onCustomerAdmin(search, loading) {
            loading(true);
            this.searchCustomerAdmin(loading, search, this);
        },
        searchCustomerAdmin: _.debounce((loading, search, vm) => {
            if (search) {
                axios.get(this.route('admin.vue.admins.search'), {
                    params: {
                        q: search,
                    }
                })
                    .then(response => {
                        vm.cucumber.options.admins = response.data.data
                        loading(false)
                    });
            }
            loading(false)
        }, 350)

    },
}
</script>
