require('./bootstrap');
require('datatables.net-bs4');
require('datatables.net-buttons-bs4');

import Vue from 'vue'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import './plugins'
Vue.component('vSelect', vSelect)

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

Vue.component('order-form-component', require('./components/orders/OrderFormComponent').default);
Vue.component('calc-component', require('./components/orders/Calc').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
