<template>
    <div>
        <taskForm :task_id="taskId" :taskFormModal.sync="taskFormModal" :onSelected="taskSelected"/>

        <b-table :items="itemsTasks" :fields="fields" striped responsive="sm">
            <template #cell(action)="row">
                <button v-b-tooltip.hover :title="trans.get('admin.general.text.show')" @click="row.toggleDetails"
                        class="btn btn-primary btn-sm m-1"><i class="fas fa-eye"></i></button>
                <button v-b-tooltip.hover :title="trans.get('admin.general.text.pause')" v-if="row.item.miscalculation == 0"
                        @click="miscalculationTask(row.item.id, 1)" class="btn btn-info btn-sm m-1"><i
                    class="fas fa-pause"></i></button>
                <button v-b-tooltip.hover :title="trans.get('admin.general.text.play')" v-if="row.item.miscalculation == 1"
                        @click="miscalculationTask(row.item.id, 0)" class="btn btn-info btn-sm m-1"><i
                    class="fas fa-play"></i></button>
                <button v-b-tooltip.hover :title="trans.get('admin.general.text.edit')" @click="editTask(row.item.id)"
                        class="btn btn-info btn-sm m-1"><i class="fas fa-pencil-alt"></i></button>
                <button v-b-tooltip.hover :title="trans.get('admin.general.text.destroy')" @click="deleteTask(row.item.id)"
                        class="btn btn-danger btn-sm m-1"><i class="fas fa-trash"></i></button>
                <a type="button" class="btn btn-sm" :class="row.item.notification && 'btn-danger' || 'btn-primary'" id="open-chat" :data-widget="row.item.id">
                    <i class="fas fa-comment-alt"></i>
                    <span v-if="row.item.notification" class="badge badge-warning navbar-badge" style="right: 0px !important; top: 0px !important; font-size: 12px !important;">{{row.item.notification}}</span>
                </a>
            </template>
            <template #cell(price)="row">
                {{ row.item.price }} <span v-if="row.item.price_custom_check"
                                           class="text-gray">({{ row.item.price_circulation }})</span>
            </template>

            <template #row-details="row">
                <b-card>
                    <div class="row">
                        <div v-for="parameter in row.item.info" class="col-12">
                            <b>{{ parameter['name'] }}</b> {{ parameter['value'] }}
                        </div>
                        <div class="col-12">
                            <b>{{ trans.get('admin.pages.tasks..text.files') }}</b> <br>
                            <div v-for="(file, index) in row.item.media" class="col-12">
                                <button v-b-tooltip.hover :title="trans.get('admin.general.text.destroy')"
                                        class="btn btn-danger btn-sm m-1"
                                        @click="deleteMedia(file['id'], index);row.item.media.splice(index, 1)"><i
                                    class="fas fa-trash"></i></button>
                                <a :href="file['url']" target="_blank">{{ file['name'] }}</a>
                            </div>
                        </div>
                    </div>
                </b-card>
            </template>
        </b-table>
    </div>
</template>

<script>
import taskForm from './TaskForm.vue'

export default {
    name: 'TaskForm',
    props: {
        order_id: {
            type: Number
        },
        itemsTasks: {},
    },

    components: {
        taskForm
    },
    computed: {},

    data() {
        return {
            fields: [
                {
                    key: 'number',
                    label: this.trans.get('admin.pages.tasks..col.id.title'),
                },
                {
                    key: 'department',
                    label: this.trans.get('admin.pages.tasks..col.departments.title'),
                },
                {
                    key: 'choice',
                    label: this.trans.get('admin.pages.tasks..col.choice.title'),
                },
                {
                    key: 'circulation',
                    label: this.trans.get('admin.pages.tasks..col.circulation.title'),
                },
                {
                    key: 'price',
                    label: this.trans.get('admin.pages.tasks..col.price.title'),
                },
                {
                    key: 'performer',
                    label: this.trans.get('admin.pages.tasks..col.performer_id.title'),
                },
                {
                    key: 'status',
                    label: this.trans.get('admin.pages.tasks..col.status.title'),
                },
                {
                    key: 'status',
                    label: this.trans.get('admin.pages.tasks..col.status.title'),
                },
                {
                    key: 'action',
                    label: this.trans.get('admin.general.text.action'),
                },
            ],

            taskId: 0,
            taskMedia: [],
            taskFormModal: false,

            taskUploadFormModal: false,
        };
    },
    mounted() {
        this.prepareComponent();
    },

    methods: {
        prepareComponent() {
        },
        editTask(id) {
            this.taskId = id
            this.taskFormModal = true
        },
        deleteMedia(id, index) {
            ConfirmDelete
            if (confirm(this.trans.get('admin.general.text.confirmDelete'))) {
                axios.post(this.route('admin.vue.media.delete'), {
                    id: id
                })
                    .then(response => {
                        this.taskMedia.splice(index, 1)
                    });
            }

        },
        deleteTask(id) {
            ConfirmDelete
            if (confirm(this.trans.get('admin.general.text.confirmDelete'))) {
                axios.post(this.route('admin.vue.task.delete'), {
                    id: id
                })
                    .then(response => {
                        back();
                        this.getTasks()
                    });
            }

        },
        miscalculationTask(id, miscalculation) {
            axios.post(this.route('admin.vue.task.miscalculation'), {
                id: id,
                miscalculation: miscalculation,
            })
                .then(response => {
                    this.getTasks()
                });
        },
        taskSelected(data) {
            this.taskFormModal = false
            this.getTasks()
        },
        getTasks() {
            axios.get(this.route('admin.vue.task.index'), {
                params: {
                    id: this.order_id,
                }
            })
                .then(response => {
                    this.$emit('update:itemsTasks', response.data.data)
                });
        },
    },
    watch: {
        'taskUploadFormModal': function (val) {
            if (!val) {
                this.getTasks()
            }
        },
        'taskFormModal': function (val) {
            this.getTasks()
        },
    }
}
</script>
