<template>
    <div v-if="open">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="format">{{ trans.get('admin.pages.tasks..form.format.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            v-model="form.format"
                            :options="options.format"
                            text-field="name"
                            value-field="value"
                            @change="priceСalculation()"
                        ></b-form-select>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="sizex"></label>
                    <div class="col-sm-9">
                        <b-form-input
                            v-model="form.size[0]"
                            @change="priceСalculation()"
                        ></b-form-input>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="sizey"></label>
                    <div class="col-sm-9">
                        <b-form-input
                            v-model="form.size[1]"
                            @change="priceСalculation()"
                        ></b-form-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="materials_options">{{
                            trans.get('admin.pages.tasks..form.materials.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            v-model="form.materials_option"
                            :options="options.materials_options[choice]"
                            text-field="name"
                            value-field="value"
                            @change="priceСalculation()"
                        ></b-form-select>
                    </div>
                </div>
            </div>

        </div>

        <div class="row">

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="color">{{ trans.get('admin.pages.tasks..form.color.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            v-model="form.color"
                            :options="options.color"
                            :disabled="true"
                            text-field="name"
                            value-field="value"
                            @change="priceСalculation()"
                        ></b-form-select>
                    </div>
                </div>
            </div>

            <div class="col-md-6" v-if="choice != 3">
                <b-button variant="primary"
                          @click="additionally_large_formatCollapse = !additionally_large_formatCollapse">{{
                        trans.get('admin.general.text.additionally')
                    }}
                </b-button>
            </div>

        </div>


        <b-collapse v-model="additionally_large_formatCollapse" v-if="choice != 3" class="mt-2">
            <b-card>

                <div v-for="(item, key) in form.additionally_large_format" class="row">

                    <div class="col-md-4">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label"
                                   for="additionally_large_format">{{ trans.get('admin.pages.tasks..form.additionally.title') }}</label>
                            <div class="col-sm-8">
                                <template v-if="choice == 0 || choice == 1">
                                    <b-form-select
                                        v-model="form.additionally_large_format[key]"
                                        :options="options.additionally_large_format.filter(function (obj) {return obj.type == 'square';})"
                                        text-field="name"
                                        value-field="value"
                                        @change="priceСalculation()"
                                    ></b-form-select>
                                </template>
                                <template v-else-if="choice == 2">
                                    <b-form-select
                                        v-model="form.additionally_large_format[key]"
                                        :options="options.additionally_large_format.filter(function (obj) {return obj.type == 'perimeter' || obj.type == 'eyelets';})"
                                        text-field="name"
                                        value-field="value"
                                        @change="priceСalculation()"
                                    ></b-form-select>
                                </template>
                                <template v-else>
                                    <b-form-select
                                        v-model="form.additionally_large_format[key]"
                                        :options="options.additionally_large_format"
                                        text-field="name"
                                        value-field="value"
                                        @change="priceСalculation()"
                                    ></b-form-select>
                                </template>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="form-group row">
                            <label class="col-sm-4 col-form-label" for="additionally_large_format_options"></label>
                            <div class="col-sm-8">
                                <b-form-select
                                    v-if="options.additionally_large_format[form.additionally_large_format[key]].type != 'eyelets'"
                                    v-model="form.additionally_large_format_option[key]"
                                    :options="options.additionally_large_format_options[form.additionally_large_format[key]]"
                                    text-field="name"
                                    value-field="value"
                                    @change="priceСalculation()"
                                ></b-form-select>
                                <b-form-input
                                    v-if="options.additionally_large_format[form.additionally_large_format[key]].type == 'eyelets'"
                                    v-model="form.additionally_large_format_option[key]"
                                    type="number"
                                    @change="priceСalculation()"
                                ></b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <div class="col-sm-12">
                                {{ text_additionally[key] }}
                            </div>
                        </div>

                    </div>

                    <div class="col-md-2">
                        <div class="form-group">
                            <div class="col-sm-12">
                                <b-button
                                    variant="outline-danger"
                                    @click="form.additionally_large_format.splice(key, 1);form.additionally_large_format_option.splice(key, 1);priceСalculation();">
                                    {{ trans.get('admin.general.text.destroy') }}
                                </b-button>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="row">

                    <div class="col-md-12">
                        <b-button class="mt-3" variant="outline-success"
                                  @click="form.additionally_large_format.push(0)">{{
                                trans.get('admin.general.text.add')
                            }}
                        </b-button>
                    </div>

                </div>
            </b-card>
        </b-collapse>


        <div class="row">

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="circulation">{{
                            trans.get('admin.pages.tasks..form.circulation.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input
                            v-model="form.circulation"
                            @change="priceСalculation()"
                        ></b-form-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label"
                           for="price_circulation">{{ trans.get('admin.pages.tasks..form.price_circulation.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.price_circulation" :disabled="true"></b-form-input>
                    </div>
                </div>
            </div>

            <template v-if="!only_calc">
                <div class="col-md-1">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <b-form-checkbox
                                id="price_custom_check"
                                v-model="form.price_custom_check"
                                :unchecked-value="false"
                                :value="true"
                                class="mt-2"
                                name="price_custom_check"
                            />
                        </div>
                    </div>
                </div>
                <template v-if="form.price_custom_check">
                    <div class="col-md-2">
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <b-form-input v-model="form.price"></b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <b-form-input v-model="form.price_notes"></b-form-input>
                            </div>
                        </div>
                    </div>
                </template>
            </template>

        </div>


        <template v-if="!only_calc">

            <div class="row">

                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label" for="note">{{ trans.get('admin.pages.tasks..form.note.title') }}</label>
                        <div class="col-sm-9">
                            <b-form-input v-model="form.note"></b-form-input>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label" for="performer_id">{{
                                trans.get('admin.pages.tasks..form.performer_id.title')
                            }}</label>
                        <div class="col-sm-9">
                            <vSelect
                                v-model="form.performer_id"
                                :filterable="false"
                                :options="options.admins"
                                :reduce="item => item.id"
                                label="name"
                                @search="onPerformer"
                            >
                                <template slot="no-options">
                                    {{ trans.get('admin.general.text.search') }}
                                </template>
                            </vSelect>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-md-12">
                    <div class="form-group">
                        <label class="col-form-label" for="taskUploadDropzone">{{ trans.get('admin.pages.tasks..title.upload') }}</label>
                        <vue-dropzone id="taskUploadDropzone" ref="taskUploadDropzone"
                                      :options="dropzoneOptions"></vue-dropzone>
                        <template v-for="(file, index) in taskMedia">
                            <button v-b-tooltip.hover :title="trans.get('admin.general.text.destroy')"
                                    class="btn btn-danger btn-sm m-1"
                                    @click="deleteMedia(file['id'], index);row.item.media.splice(index, 1)"><i
                                class="fas fa-trash"></i></button>
                            <a :href="file['url']" target="_blank">{{ file['name'] }}</a>
                        </template>
                    </div>
                </div>

            </div>

            <b-button class="mt-3" variant="outline-success" @click="taskEdit(0)">{{
                    trans.get('admin.general.text.play')
                }}
            </b-button>
            <b-button class="mt-3" variant="outline-success" @click="taskEdit(1)">{{
                    trans.get('admin.general.text.save_calculation')
                }}
            </b-button>
        </template>
    </div>
</template>

<script>

import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: "UfPrinting",
    props: {
        name: {
            type: String
        },
        date: {
            type: String,
            default: null
        },
        manager_id: {
            type: Number
        },
        order_id: {
            type: Number
        },
        task_id: {
            type: Number,
            default: 0
        },
        department: {
            type: Number,
            default: 0
        },
        choice: {
            type: Number,
            default: 0
        },
        open: {
            type: Boolean,
            default: false
        },
        taskFormModal: {
            type: Boolean,
            default: false
        },
        unknown_task: {
            type: Boolean,
            default: false
        },
        only_calc: {
            type: Boolean,
            default: false
        },
    },

    components: {
        vueDropzone: vue2Dropzone
    },
    computed: {},

    data() {
        return {
            errors: [],
            form: {
                name: this.name,
                department: this.department,
                choice: this.choice,
                format: 'a2',
                size: [420, 594],
                materials: this.choice,
                materials_option: 0,
                additionally_large_format: [],
                additionally_large_format_option: [0],
                color: 0,
                circulation: 0,
                price_circulation: 0,
                price_custom_check: false,
                price: null,
                price_notes: null,
                note: null,
                miscalculation: 0,
                performer_id: 18,
                type_calculator: 'UfPrinting',
            },
            text_additionally: [],
            options: {
                admins: [],
                departments: [],
                departments_options: [],
                format: [
                    {"value": "a3", "name": 'A3'},
                    {"value": "a2", "name": 'A2'},
                    {"value": "a1", "name": 'A1'},
                    {"value": "a0", "name": 'A0'},
                    {"value": "other", "name": 'Інше'},
                ],
                color: [],
                materials: [],
                materials_options: [],
                additionally_large_format: [],
                additionally_large_format_options: [],
            },
            dropzoneOptions: {
                url: '',
                thumbnailWidth: 150,
                maxFilesize: 500,
                maxFiles: 10,
                method: 'POST',
            },
            taskMedia: [],
            additionally_large_formatCollapse: false,
            form_id: null
        };
    },

    methods: {
        prepareComponent() {
            this.getSetting('materials')
            this.getSetting('color')
            this.getSetting('additionally_large_format')
            this.getAdmin(18)
            this.form_id = this.task_id
            if (this.form_id && this.unknown_task == false) {
                this.getTask(this.form_id)
            }

            if (this.form_id) {
                this.dropzoneOptions.url = this.route('admin.vue.task.upload', this.form_id)
            }
        },

        getTask(id) {
            axios.get(this.route('admin.vue.task.info'), {
                params: {
                    id: id,
                }
            })
                .then(response => {

                    this.form_id = response.data.data.id
                    this.form.name = response.data.data.name
                    this.form.price = response.data.data.price
                    this.form.price_notes = response.data.data.price_notes
                    this.form.price_custom_check = response.data.data.price_custom_check
                    this.form.note = response.data.data.note
                    this.form.miscalculation = response.data.data.miscalculation ?? 0
                    this.form.performer_id = response.data.data.performer_id ?? 18

                    this.form.format = response.data.data.parameters.format ?? 'a2'
                    this.form.size = response.data.data.parameters.size ?? [420, 594]
                    this.form.materials = response.data.data.parameters.materials ?? this.choice
                    this.form.materials_option = response.data.data.parameters.materials_option ?? 0
                    this.form.additionally_large_format = response.data.data.parameters.additionally_large_format ?? []
                    this.form.additionally_large_format_option = response.data.data.parameters.additionally_large_format_option ?? []
                    this.form.color = response.data.data.parameters.color ?? 0
                    this.form.circulation = response.data.data.parameters.circulation ?? 0
                    this.form.price_circulation = response.data.data.parameters.price_circulation ?? 0

                    if (response.data.data.parameters.additionally_large_format.length > 0) {
                        this.additionally_large_formatCollapse = true;
                    }

                    this.taskMedia = response.data.data.files

                    this.getAdmin(response.data.data.performer_id)
                });

        },

        priceСalculation() {
            this.form.name = this.name
            this.form.department = this.department
            this.form.choice = this.choice
            this.form.materials = this.choice
            axios.post(this.route('admin.vue.calculation.info'), this.form)
                .then(response => {
                    this.form.price_circulation = response.data.data.price
                    if (!this.form.price_custom_check) {
                        this.form.price = response.data.data.price
                    }
                    this.text_additionally = response.data.data.info

                    if(this.form_id){
                        this.dropzoneOptions.url = this.route('admin.vue.task.upload', this.form_id)
                    }
                });
        },

        getSetting(name) {
            axios.get(this.route('admin.vue.setting.info'), {
                params: {
                    system_name: name,
                }
            })
                .then(response => {
                    if (name == 'materials') {
                        this.options.materials = response.data.data.materials
                        this.options.materials_options = response.data.data.materials_options
                    } else if (name == 'additionally_large_format') {
                        this.options.additionally_large_format = response.data.data.additionally_large_format
                        this.options.additionally_large_format_options = response.data.data.additionally_large_format_options
                    } else {
                        this.options[name] = response.data.data
                    }
                });

        },
        taskEdit(miscalculation = 1) {
            this.form.name = this.name
            this.form.department = this.department
            this.form.choice = this.choice
            this.form.date = this.date
            this.form.materials = this.choice
            this.form.miscalculation = miscalculation
            axios.post(this.route('admin.vue.task.update'), Object.assign(this.form, {
                id: this.form_id,
                order_id: this.order_id,
                quantity: this.text_additionally[0]
            }))
                .then(response => {
                    this.$emit('update:taskFormModal', false)
                    this.$bvToast.toast(this.trans.get('admin.general.text.success'), {
                        title: response.data.message,
                        variant: 'success',
                        solid: true
                    })
                })
                .catch(error => {
                    let errors = Object.values(error.response.data.errors);
                    errors = errors.flat();
                    this.errors = errors
                });
        },

        getAdmin(id) {
            if (id != null) {
                axios.get(this.route('admin.vue.admin.info'), {
                    params: {
                        id: id,
                    }
                })
                    .then(response => {
                        if (this.options.admins.find(item => item.id == id) === undefined) {
                            this.options.admins.push(response.data.data)
                        }
                    });
            }
        },

        onPerformer(search, loading) {
            loading(true);
            this.searchPerformer(loading, search, this);
        },
        searchPerformer: _.debounce((loading, search, vm) => {
            if (search) {
                axios.get(vm.route('admin.vue.admins.search'), {
                    params: {
                        q: search,
                    }
                })
                    .then(response => {
                        vm.options.admins = response.data.data
                        loading(false)
                    });
            }
            loading(false)
        }, 350),

        deleteMedia(id, index) {
            ConfirmDelete
            if (confirm(this.trans.get('admin.general.text.confirmDelete'))) {
                axios.post(this.route('admin.vue.media.delete'), {
                    id: id
                })
                    .then(response => {
                        this.taskMedia.splice(index, 1)
                    });
            }

        },
    },


    watch: {
        'form.format': function (val) {
            switch (val) {
                case 'a3':
                    this.form.size = [297, 420];
                    break;
                case 'a2':
                    this.form.size = [420, 594];
                    break;
                case 'a1':
                    this.form.size = [594, 841];
                    break;
                case 'a0':
                    this.form.size = [841, 1189];
                    break;
            }

        },
        'form.size': function (val) {
            if (val[0] == 297 && val[1] == 420) {
                this.form.format = 'a3'
            } else if (val[0] == 420 && val[1] == 594) {
                this.form.format = 'a2'
            } else if (val[0] == 594 && val[1] == 841) {
                this.form.format = 'a1'
            } else if (val[0] == 841 && val[1] == 1189) {
                this.form.format = 'a0'
            } else {
                this.form.format = 'other'
            }
            this.priceСalculation()
        },
        'open': function (val) {
            if (val) {
                this.prepareComponent();
                if (this.form_id && this.unknown_task == false) {
                    this.getTask(this.form_id)
                }
            }
        },
        'choice': function (val) {
            this.priceСalculation()
        },
    }

}
</script>

<style scoped>

</style>
