<template>
    <div v-if="open">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="format">{{ trans.get('admin.pages.tasks..form.format.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            ref="format"
                            v-model="form.format"
                            :options="options.format"
                            text-field="name"
                            value-field="value"
                            @change="priceСalculation()"
                        ></b-form-select>
                    </div>
                </div>
            </div>

            <div class="col-md-6 row">
                <div class="col-md-3">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <b-form-input
                                ref="size"
                                v-model="form.size[0]"
                                max="310"
                                @change="priceСalculation()"
                            ></b-form-input>
                            <p v-if="errors.size" class="w-bold text-red">
                                {{ errors.size }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <b-form-input
                                ref="size"
                                v-model="form.size[1]"
                                max="440"
                                @change="priceСalculation()"
                            ></b-form-input>
                            <p v-if="errors.size" class="w-bold text-red">
                                {{ errors.size }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="col-md-2">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <b-form-checkbox
                                id="album"
                                ref="album"
                                v-model="form.album"
                                :unchecked-value="false"
                                :value="true"
                                class="mt-2"
                                name="album"
                                @change="priceСalculation()"
                            >
                                {{ trans.get('admin.pages.tasks..form.album.title') }}
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <b-form-select
                                ref="types_binding"
                                v-model="form.types_binding"
                                :options="options.types_binding"
                                text-field="name"
                                value-field="value"
                                @change="changeTypesBinding()"
                            ></b-form-select>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <hr>

        <div class="row">
            <div class="col-6">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="text-center">{{ trans.get('admin.pages.tasks..text.indoor') }}</h3>
                        <br>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label" for="paper_type">{{
                                    trans.get('admin.pages.tasks..form.paper_type.title')
                                }}</label>
                            <div class="col-sm-9">
                                <b-form-select
                                    ref="indoor.paper_type"
                                    v-model="form.indoor.paper_type"
                                    :options="options.paper_type"
                                    text-field="name"
                                    value-field="value"
                                    @change="priceСalculation()"
                                ></b-form-select>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label" for="paper_type_options"></label>
                            <div class="col-sm-9">
                                <b-form-select
                                    ref="indoor.paper_type_option"
                                    v-model="form.indoor.paper_type_option"
                                    :options="options.paper_type_options[form.indoor.paper_type]"
                                    text-field="name"
                                    value-field="value"
                                    @change="priceСalculation()"
                                ></b-form-select>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">

                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label" for="color">{{
                                    trans.get('admin.pages.tasks..form.color.title')
                                }}</label>
                            <div class="col-sm-9">
                                <b-form-select
                                    ref="indoor.color"
                                    v-model="form.indoor.color"
                                    :options="options.color"
                                    text-field="name"
                                    value-field="value"
                                    @change="priceСalculation()"
                                ></b-form-select>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <div class="form-group row">
                            <label class="col-sm-3 col-form-label" for="pages">{{
                                    trans.get('admin.pages.tasks..form.pages.title')
                                }}</label>
                            <div class="col-sm-9">
                                <b-form-input
                                    ref="indoor.pages"
                                    v-model="form.indoor.pages"
                                    @change="priceСalculation()"
                                ></b-form-input>
                                <p v-if="form.types_binding == 0 && form.indoor.pages % 4 != 0" class="w-bold text-red">
                                    {{
                                        trans.get('admin.pages.tasks..text.pages_error')
                                    }}</p>
                                <p v-if="form.types_binding != 0 && errors.pages" class="w-bold text-red">
                                    {{
                                        errors.pages
                                    }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12">
                        <b-button variant="primary" @click="additionallyCollapseIndoor = !additionallyCollapseIndoor">{{
                                trans.get('admin.general.text.additionally')
                            }}
                        </b-button>
                    </div>

                </div>


                <b-collapse v-model="additionallyCollapseIndoor" class="mt-2">
                    <b-card>

                        <div v-for="(item, key) in form.indoor.additionally" class="row">

                            <div class="col-md-7">
                                <div class="form-group row">
                                    <label class="col-sm-3 col-form-label" for="additionally">{{
                                            trans.get('admin.pages.tasks..form.additionally.title')
                                        }}</label>
                                    <div class="col-sm-9">
                                        <b-form-select
                                            ref="indoor.additionally"
                                            v-model="form.indoor.additionally[key]"
                                            :options="options.additionally"
                                            text-field="name"
                                            value-field="value"
                                            @change="priceСalculation()"
                                        ></b-form-select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="form-group">
                                    <b-form-select
                                        ref="indoor.additionally_option"
                                        v-model="form.indoor.additionally_option[key]"
                                        :options="options.additionally_options[form.indoor.additionally[key]]"
                                        text-field="name"
                                        value-field="value"
                                        @change="priceСalculation()"
                                    ></b-form-select>
                                </div>
                            </div>

                            <div class="col-md-1">
                                <div class="form-group">
                                    <div class="col-sm-12">
                                        <b-button
                                            variant="outline-danger"
                                            @click="form.indoor.additionally.splice(key, 1);form.indoor.additionally_option.splice(key, 1);priceСalculation();">
                                            <i class="fas fa-trash"></i>
                                        </b-button>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="row">

                            <div class="col-md-12">
                                <b-button class="mt-3" variant="outline-success"
                                          @click="form.indoor.additionally.push(0)">{{
                                        trans.get('admin.general.text.add')
                                    }}
                                </b-button>
                            </div>

                        </div>
                    </b-card>
                </b-collapse>
            </div>
            <div class="col-6">
                <div class="row">
                    <div class="col-md-12">
                        <h3 class="text-center">{{ trans.get('admin.pages.tasks..text.cover') }}</h3>
                        <b-form-checkbox
                            ref="coverActive"
                            id="coverActive"
                            v-model="form.coverActive"
                            :unchecked-value="false"
                            :value="true"
                            class="mt-2"
                            name="coverActive"
                            @change="priceСalculation()"
                        >
                            {{ trans.get('admin.pages.tasks..text.cover') }}
                        </b-form-checkbox>
                    </div>

                </div>
                <div v-if="form.coverActive">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="paper_type">{{
                                        trans.get('admin.pages.tasks..form.paper_type.title')
                                    }}</label>
                                <div class="col-sm-9">
                                    <b-form-select
                                        ref="cover.paper_type"
                                        v-model="form.cover.paper_type"
                                        :options="options.paper_type"
                                        text-field="name"
                                        value-field="value"
                                        @change="priceСalculation()"
                                    ></b-form-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="paper_type_options"></label>
                                <div class="col-sm-9">
                                    <b-form-select
                                        ref="cover.paper_type_option"
                                        v-model="form.cover.paper_type_option"
                                        :options="options.paper_type_options[form.cover.paper_type]"
                                        text-field="name"
                                        value-field="value"
                                        @change="priceСalculation()"
                                    ></b-form-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">

                        <div class="col-md-12">
                            <div class="form-group row">
                                <label class="col-sm-3 col-form-label" for="color">{{
                                        trans.get('admin.pages.tasks..form.color.title')
                                    }}</label>
                                <div class="col-sm-9">
                                    <b-form-select
                                        ref="cover.color"
                                        v-model="form.cover.color"
                                        :options="options.color_cover"
                                        text-field="name"
                                        value-field="value"
                                        @change="priceСalculation()"
                                    ></b-form-select>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group row">
<!--                                <label class="col-sm-3 col-form-label" for="pages">{{-->
<!--                                        trans.get('admin.pages.tasks..form.pages.title')-->
<!--                                    }}</label>-->
                                <div class="col-sm-9">
                                    <b-form-input
                                        ref="cover.pages"
                                        v-model="form.cover.pages"
                                        :disabled="true"
                                        hidden="true"
                                    ></b-form-input>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <b-button variant="primary" @click="additionallyCollapseCover = !additionallyCollapseCover">
                                {{
                                    trans.get('admin.general.text.additionally')
                                }}
                            </b-button>
                        </div>

                    </div>
                    <b-collapse v-model="additionallyCollapseCover" class="mt-2">
                        <b-card>

                            <div v-for="(item, key) in form.cover.additionally" class="row">

                                <div class="col-md-7">
                                    <div class="form-group row">
                                        <label class="col-sm-3 col-form-label" for="additionally">{{
                                                trans.get('admin.pages.tasks..form.additionally.title')
                                            }}</label>
                                        <div class="col-sm-9">
                                            <b-form-select
                                                ref="cover.additionally"
                                                v-model="form.cover.additionally[key]"
                                                :options="options.additionally"
                                                text-field="name"
                                                value-field="value"
                                                @change="priceСalculation()"
                                            ></b-form-select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group row">
                                        <b-form-select
                                            ref="cover.additionally_option"
                                            v-model="form.cover.additionally_option[key]"
                                            :options="options.additionally_options[form.cover.additionally[key]]"
                                            text-field="name"
                                            value-field="value"
                                            @change="priceСalculation()"
                                        ></b-form-select>
                                    </div>
                                </div>

                                <div class="col-md-1">
                                    <div class="form-group">
                                        <div class="col-sm-12">
                                            <b-button
                                                variant="outline-danger"
                                                @click="form.cover.additionally.splice(key, 1);form.cover.additionally_option.splice(key, 1);priceСalculation();">
                                                <i class="fas fa-trash"></i>
                                            </b-button>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div class="row">

                                <div class="col-md-12">
                                    <b-button class="mt-3" variant="outline-success"
                                              @click="form.cover.additionally.push(0)">{{
                                            trans.get('admin.general.text.add')
                                        }}
                                    </b-button>
                                </div>

                            </div>
                        </b-card>
                    </b-collapse>
                </div>
            </div>
        </div>

        <hr>


        <div class="row">

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="circulation">{{
                            trans.get('admin.pages.tasks..form.circulation.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input
                            v-model="form.circulation"
                            @change="priceСalculation()"
                        ></b-form-input>
                        <p v-if="errors.circulation" class="w-bold text-red">
                            {{
                                errors.circulation
                            }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="price_circulation">{{
                            trans.get('admin.pages.tasks..form.price_circulation.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.price_circulation" :disabled="true"></b-form-input>
                    </div>
                </div>
            </div>


            <template v-if="!only_calc">
                <div class="col-md-1">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <b-form-checkbox
                                id="price_custom_check"
                                v-model="form.price_custom_check"
                                :unchecked-value="false"
                                :value="true"
                                class="mt-2"
                                name="price_custom_check"
                            />
                        </div>
                    </div>
                </div>
                <template v-if="form.price_custom_check">
                    <div class="col-md-2">
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <b-form-input v-model="form.price"></b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <b-form-input v-model="form.price_notes"></b-form-input>
                            </div>
                        </div>
                    </div>
                </template>
            </template>

        </div>

        <template v-if="!only_calc">

            <div class="row">

                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label" for="note">{{ trans.get('admin.pages.tasks..form.note.title') }}</label>
                        <div class="col-sm-9">
                            <b-form-input v-model="form.note"></b-form-input>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">

                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label" for="performer_id">{{
                                trans.get('admin.pages.tasks..form.performer_id.title')
                            }}</label>
                        <div class="col-sm-9">
                            <vSelect
                                v-model="form.performer_id"
                                :filterable="false"
                                :options="options.admins"
                                :reduce="item => item.id"
                                label="name"
                                @search="onPerformer"
                            >
                                <template slot="no-options">
                                    {{ trans.get('admin.general.text.search') }}
                                </template>
                            </vSelect>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row">

                <div class="col-md-12">
                    <div class="form-group">
                        <label class="col-form-label" for="taskUploadDropzone">{{ trans.get('admin.pages.tasks..title.upload') }}</label>
                        <vue-dropzone id="taskUploadDropzone" ref="taskUploadDropzone"
                                      :options="dropzoneOptions"></vue-dropzone>
                        <template v-for="(file, index) in taskMedia">
                            <button v-b-tooltip.hover :title="trans.get('admin.general.text.destroy')"
                                    class="btn btn-danger btn-sm m-1"
                                    @click="deleteMedia(file['id'], index);row.item.media.splice(index, 1)"><i
                                class="fas fa-trash"></i></button>
                            <a :href="file['url']" target="_blank">{{ file['name'] }}</a>
                        </template>
                    </div>
                </div>

            </div>

            <b-button class="mt-3" variant="outline-success" @click="taskEdit(0)">{{
                    trans.get('admin.general.text.play')
                }}
            </b-button>
            <b-button class="mt-3" variant="outline-success" @click="taskEdit(1)">{{
                    trans.get('admin.general.text.save_calculation')
                }}
            </b-button>
        </template>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: "Brochures",
    props: {
        name: {
            type: String
        },
        date: {
            type: String,
            default: null
        },
        manager_id: {
            type: Number
        },
        order_id: {
            type: Number
        },
        task_id: {
            type: Number,
            default: 0
        },
        department: {
            type: Number,
            default: 0
        },
        choice: {
            type: Number,
            default: 0
        },
        open: {
            type: Boolean,
            default: false
        },
        taskFormModal: {
            type: Boolean,
            default: false
        },
        only_calc: {
            type: Boolean,
            default: false
        },
    },

    components: {
        vueDropzone: vue2Dropzone
    },
    computed: {},

    data() {
        return {
            errors: [],
            form: {
                name: this.name,
                department: this.department,
                choice: this.choice,
                format: 'a4',
                size: [210, 297],
                album: false,
                types_binding: 0,

                indoor: {
                    paper_type: 0,
                    paper_type_option: 0,
                    additionally: [],
                    additionally_option: [0],
                    color: 1,
                    pages: 0,
                },
                coverActive: false,
                cover: {
                    paper_type: 0,
                    paper_type_option: 0,
                    additionally: [],
                    additionally_option: [0],
                    color: 0,
                    pages: 2,
                },

                circulation: 0,
                price_circulation: 0,
                price: null,
                price_notes: null,
                price_custom_check: false,
                note: null,
                miscalculation: 0,
                performer_id: 12,
                type_calculator: 'Brochures',
            },
            options: {
                admins: [],
                departments: [],
                departments_options: [],
                format: [
                    {"value": "a3", "name": 'A3'},
                    {"value": "a4", "name": 'A4'},
                    {"value": "a5", "name": 'A5'},
                    {"value": "a6", "name": 'A6'},
                    {"value": "evro", "name": 'Євро'},
                    {"value": "other", "name": 'Інше'},
                ],
                color: [],
                color_cover: [],
                types_binding: [],
                paper_type: [],
                paper_type_options: [],
                additionally: [],
                additionally_options: [],
            },
            dropzoneOptions: {
                url: '',
                thumbnailWidth: 150,
                maxFilesize: 500,
                maxFiles: 10,
                method: 'POST',
            },
            taskMedia: [],
            additionallyCollapseIndoor: false,
            additionallyCollapseCover: false,
            form_id: null
        };
    },
    mounted() {
        if (this.open) {
            this.prepareComponent();
        }
    },

    methods: {
        prepareComponent() {
            this.getSetting('paper_type')
            this.getSetting('color')
            this.getSetting('types_binding')
            this.getSetting('additionally')
            this.getAdmin(12)
            this.form_id = this.task_id
            if (this.form_id && this.unknown_task == false) {
                this.getTask(this.form_id)
            }
            if (this.form_id) {
                this.dropzoneOptions.url = this.route('admin.vue.task.upload', this.form_id)
            }
        },

        getTask(id) {
            axios.get(this.route('admin.vue.task.info'), {
                params: {
                    id: id,
                }
            })
                .then(response => {
                    this.form_id = response.data.data.id
                    this.form.name = response.data.data.name
                    this.form.price = response.data.data.price ?? 0
                    this.form.price_notes = response.data.data.price_notes
                    this.form.price_custom_check = response.data.data.price_custom_check
                    this.form.note = response.data.data.note
                    this.form.miscalculation = response.data.data.miscalculation ?? 0
                    this.form.performer_id = response.data.data.performer_id ?? 12

                    this.form.format = response.data.data.parameters.format ?? 'a4'
                    this.form.size = response.data.data.parameters.size ?? [210, 297]
                    this.form.album = response.data.data.parameters.album ?? false
                    this.form.types_binding = response.data.data.parameters.types_binding ?? 0

                    this.form.indoor = response.data.data.parameters.indoor ?? {
                        paper_type: 0,
                        paper_type_option: 0,
                        additionally: [],
                        additionally_option: [0],
                        color: 1,
                        pages: 0,
                    }

                    this.form.cover = response.data.data.parameters.cover ?? {
                        paper_type: 0,
                        paper_type_option: 0,
                        additionally: [],
                        additionally_option: [0],
                        color: 0,
                        pages: 2,
                    };

                    this.form.coverActive = response.data.data.parameters.coverActive ?? false

                    this.form.circulation = response.data.data.parameters.circulation ?? 0
                    this.form.price_circulation = response.data.data.parameters.price_circulation ?? 0

                    if (response.data.data.parameters.indoor.additionally.length > 0) {
                        this.additionallyCollapseIndoor = true;
                    }
                    if (this.form.coverActive && response.data.data.parameters.cover.additionally.length > 0) {
                        this.additionallyCollapseCover = true;
                    }
                    this.taskMedia = response.data.data.files

                    this.getAdmin(response.data.data.performer_id)
                });

        },

        priceСalculation() {
            this.form.name = this.name
            this.form.department = this.department
            this.form.choice = this.choice
            this.errors = []
            axios.post(this.route('admin.vue.calculation.info'), this.form)
                .then(response => {
                    this.form.price_circulation = response.data.data.price
                    if (!this.form.price_custom_check) {
                        this.form.price = response.data.data.price
                    }

                    if (response.data.data.error) {
                        this.errors = response.data.data.error
                        this.$bvToast.toast(' ', {
                            title: this.trans.get('admin.general.text.errors'),
                            variant: 'warning',
                            solid: true
                        })
                    }
                    if (response.data.data.options) {
                        //
                    }
                    if(this.form_id){
                        this.dropzoneOptions.url = this.route('admin.vue.task.upload', this.form_id)
                    }
                });
        },

        changeTypesBinding() {
            if (this.form.types_binding == 0) {
                this.options.color = this.options.color.filter(function (obj) {
                    return obj.name !== '4+0' && obj.name !== '1+0';
                });
                this.form.indoor.color = 1
            } else {
                if (this.options.color.find(item => item.name == "4+0") === undefined) {
                    this.options.color.push({"value": 0, "name": "4+0",})
                    this.options.color.push({"value": 2, "name": "1+0",})
                }
            }
            this.priceСalculation()
        },

        getSetting(name) {
            axios.get(this.route('admin.vue.setting.info'), {
                params: {
                    system_name: name,
                }
            })
                .then(response => {
                    if (name == 'paper_type') {
                        this.options.paper_type = response.data.data.paper_type
                        this.options.paper_type_options = response.data.data.paper_type_options
                    } else if (name == 'additionally') {
                        this.options.additionally = response.data.data.additionally
                        this.options.additionally_options = response.data.data.additionally_options
                    } else {
                        this.options[name] = response.data.data
                    }

                    if (name == 'color') {
                        this.options.color_cover = response.data.data

                        this.changeTypesBinding()
                    }
                });

        },
        taskSend(miscalculation = 0) {
            this.form.name = this.name
            this.form.department = this.department
            this.form.choice = this.choice
            this.form.miscalculation = miscalculation
            axios.post(this.route('admin.vue.task.store'), Object.assign(this.form, {
                order_id: this.order_id,
                manager_id: this.manager_id
            }))
                .then(response => {
                    this.form_id = response.data.data.id

                    this.dropzoneOptions.url = this.route('admin.vue.task.upload', this.form_id)

                    this.$bvToast.toast(this.trans.get('admin.general.text.success'), {
                        title: response.data.message,
                        variant: 'success',
                        solid: true
                    })
                })
                .catch(error => {
                    let errors = Object.values(error.response.data.errors);
                    errors = errors.flat();
                    this.errors = errors
                });
        },

        taskEdit(miscalculation = 1) {
            this.form.name = this.name
            this.form.department = this.department
            this.form.choice = this.choice
            this.form.miscalculation = miscalculation
            axios.post(this.route('admin.vue.task.update'), Object.assign(this.form, {
                id: this.form_id,
                order_id: this.order_id
            }))
                .then(response => {

                    this.$emit('update:taskFormModal', false)

                    this.$bvToast.toast(this.trans.get('admin.general.text.success'), {
                        title: response.data.message,
                        variant: 'success',
                        solid: true
                    })
                })
                .catch(error => {
                    let errors = Object.values(error.response.data.errors);
                    errors = errors.flat();
                    this.errors = errors
                });
        },

        getAdmin(id) {
            if (id != null) {
                axios.get(this.route('admin.vue.admin.info'), {
                    params: {
                        id: id,
                    }
                })
                    .then(response => {
                        if (this.options.admins.find(item => item.id == id) === undefined) {
                            this.options.admins.push(response.data.data)
                        }
                    });
            }
        },
        onPerformer(search, loading) {
            loading(true);
            this.searchPerformer(loading, search, this);
        },
        searchPerformer: _.debounce((loading, search, vm) => {
            if (search) {
                axios.get(vm.route('admin.vue.admins.search'), {
                    params: {
                        q: search,
                    }
                })
                    .then(response => {
                        vm.options.admins = response.data.data
                        loading(false)
                    });
            }
            loading(false)
        }, 350),

        deleteMedia(id, index) {
            ConfirmDelete
            if (confirm(this.trans.get('admin.general.text.confirmDelete'))) {
                axios.post(this.route('admin.vue.media.delete'), {
                    id: id
                })
                    .then(response => {
                        this.taskMedia.splice(index, 1)
                    });
            }

        },
    },


    watch: {
        'form.format': function (val) {
            switch (val) {
                case 'a3':
                    this.form.size = [297, 420];
                    break;
                case 'a4':
                    this.form.size = [210, 297];
                    break;
                case 'a5':
                    this.form.size = [148, 210];
                    break;
                case 'a6':
                    this.form.size = [105, 148];
                    break;
                case 'evro':
                    this.form.size = [100, 210];
                    break;
            }
        },
        'form.size': function (val) {
            if (val[0] == 297 && val[1] == 420) {
                this.form.format = 'a3'
            } else if (val[0] == 210 && val[1] == 297) {
                this.form.format = 'a4'
            } else if (val[0] == 148 && val[1] == 210) {
                this.form.format = 'a5'
            } else if (val[0] == 105 && val[1] == 148) {
                this.form.format = 'a6'
            } else if (val[0] == 100 && val[1] == 210) {
                this.form.format = 'evro'
            } else {
                this.form.format = 'other'
            }
            this.priceСalculation()
        },
        'open': function (val) {
            if (val) {
                this.prepareComponent();
                if (this.form_id) {
                    this.getTask(this.form_id)
                }
            }
        },
    }

}
</script>

<style scoped>

</style>
