import Vue from 'vue'
Vue.mixin({
    methods: {
        route: (name, params, absolute) => route(`${name}`, params, absolute),
        isRoute: (name) => route(null, {}, null, Ziggy).current(name),
        isRouteCurrent: (name) => route(null, {}, null, Ziggy).current(name + '.*'),
        routeIncludes: (fragments) => route(null, {}, null, Ziggy)
            .current()
            .match(new RegExp(`(${fragments.join('|')})`)),
    },
})
