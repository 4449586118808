<template>
    <div v-if="open">


        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="format">{{ trans.get('admin.pages.tasks..form.format.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            v-model="form.glyanec_format"
                            :options="options.format"
                            text-field="name"
                            value-field="value"
                            @change="priceСalculation()"
                        ></b-form-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="options.glyanec_booklet && form.glyanec_format">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="material">{{ trans.get('admin.pages.tasks..form.materials.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            v-model="form.glyanec_material"
                            :options="options.glyanec_booklet[form.glyanec_format]"
                            text-field="name"
                            value-field="id"
                            @change="priceСalculation()"
                        ></b-form-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="options.glyanec_booklet && form.glyanec_format && form.glyanec_material">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="coating">{{ trans.get('admin.pages.tasks..form.coatings.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            v-model="form.glyanec_coating"
                            :options="options.glyanec_booklet[form.glyanec_format][form.glyanec_material]['coatings']"
                            text-field="name"
                            value-field="id"
                            @change="priceСalculation()"
                        ></b-form-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="options.glyanec_booklet && form.glyanec_format && form.glyanec_material && form.glyanec_coating">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="color">{{ trans.get('admin.pages.tasks..form.color.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            v-model="form.glyanec_color"
                            :options="options.glyanec_booklet[form.glyanec_format][form.glyanec_material]['coatings'][form.glyanec_coating]['colors']"
                            text-field="name"
                            value-field="id"
                            @change="priceСalculation()"
                        ></b-form-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="circulation">{{
                            trans.get('admin.pages.tasks..form.circulation.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-select
                            v-model="form.circulation"
                            :options="options.circulation"
                            text-field="name"
                            value-field="value"
                            @change="priceСalculation()"
                        ></b-form-select>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="price_circulation">{{
                            trans.get('admin.pages.tasks..form.price_circulation.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.price_circulation" :disabled="true"></b-form-input>
                    </div>
                </div>
            </div>

            <template v-if="!only_calc">
                <div class="col-md-1">
                    <div class="form-group row">
                        <div class="col-sm-12">
                            <b-form-checkbox
                                id="price_custom_check"
                                v-model="form.price_custom_check"
                                :unchecked-value="false"
                                :value="true"
                                class="mt-2"
                                name="price_custom_check"
                            />
                        </div>
                    </div>
                </div>
                <template v-if="form.price_custom_check">
                    <div class="col-md-2">
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <b-form-input v-model="form.price"></b-form-input>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group row">
                            <div class="col-sm-12">
                                <b-form-input v-model="form.price_notes"></b-form-input>
                            </div>
                        </div>
                    </div>
                </template>
            </template>

        </div>

        <div class="row">

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="note">{{ trans.get('admin.pages.tasks..form.note.title') }}</label>
                    <div class="col-sm-9">
                        <b-form-input v-model="form.note"></b-form-input>
                    </div>
                </div>
            </div>

        </div>

        <template v-if="!only_calc">
            <div class="row">

                <div class="col-md-6">
                    <div class="form-group row">
                        <label class="col-sm-3 col-form-label" for="performer_id">{{
                                trans.get('admin.pages.tasks..form.performer_id.title')
                            }}</label>
                        <div class="col-sm-9">
                            <vSelect
                                v-model="form.performer_id"
                                :filterable="false"
                                :options="options.admins"
                                :reduce="item => item.id"
                                label="name"
                                @search="onPerformer"
                                :disabled="true"
                            >
                                <template slot="no-options">
                                    {{ trans.get('admin.general.text.search') }}
                                </template>
                            </vSelect>
                        </div>
                    </div>
                </div>

            </div>
        </template>

        <div class="row">

            <div class="col-md-6">
                <div class="form-group row">
                    <label class="col-sm-3 col-form-label" for="reminder_date">{{
                            trans.get('admin.pages.tasks..form.reminder_date.title')
                        }}</label>
                    <div class="col-sm-9">
                        <b-form-input
                            v-model="form.reminder_date"
                            type="date"
                            :disabled="true"
                        ></b-form-input>
                    </div>
                </div>
            </div>

        </div>

        <template v-if="!only_calc">

            <div class="row">

                <div class="col-md-12">
                    <div class="form-group">
                        <a :href="info.file_url" target="_blank">{{ info.file_name }}</a>
                        <a :href="info.site_url" target="_blank">{{ trans.get('admin.pages.tasks..title.glyanec') }}</a>
                    </div>
                </div>

            </div>
            <div class="row">

                <div class="col-md-12">
                    <div class="form-group">
                        <label class="col-form-label" for="taskUploadDropzone">{{ trans.get('admin.pages.tasks..title.upload') }}</label>
                        <vue-dropzone id="taskUploadDropzone" ref="taskUploadDropzone"
                                      :options="dropzoneOptions"></vue-dropzone>
                        <template v-for="(file, index) in taskMedia">
                            <button v-b-tooltip.hover :title="trans.get('admin.general.text.destroy')"
                                    class="btn btn-danger btn-sm m-1"
                                    @click="deleteMedia(file['id'], index);row.item.media.splice(index, 1)"><i
                                class="fas fa-trash"></i></button>
                            <a :href="file['url']" target="_blank">{{ file['name'] }}</a>
                        </template>
                    </div>
                </div>

            </div>

            <b-button class="mt-3" variant="outline-success" @click="taskEdit(0)">{{
                    trans.get('admin.general.text.play')
                }}
            </b-button>
            <b-button class="mt-3" variant="outline-success" @click="taskEdit(1)">{{
                    trans.get('admin.general.text.save_calculation')
                }}
            </b-button>
        </template>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: "GlyanecBooklet",
    props: {
        name: {
            type: String
        },
        date: {
            type: String,
            default: null
        },
        manager_id: {
            type: Number
        },
        order_id: {
            type: Number
        },
        task_id: {
            type: Number,
            default: 0
        },
        department: {
            type: Number,
            default: 0
        },
        choice: {
            type: Number,
            default: 0
        },
        open: {
            type: Boolean,
            default: false
        },
        taskFormModal: {
            type: Boolean,
            default: false
        },
        unknown_task: {
            type: Boolean,
            default: false
        },
        only_calc: {
            type: Boolean,
            default: false
        },
    },

    components: {
        vueDropzone: vue2Dropzone
    },
    computed: {},

    data() {
        return {
            errors: [],
            info: [],
            form: {
                name: this.name,
                department: this.department,
                choice: this.choice,
                glyanec_format: 'evro_booklet',
                glyanec_material: 0,
                glyanec_coating: 0,
                glyanec_color: 0,
                circulation: 500,
                price_circulation: 0,
                price_custom_check: false,
                price: null,
                reminder_date: null,
                price_notes: null,
                note: null,
                date: null,
                miscalculation: 0,
                performer_id: 21,
                type_calculator: 'GlyanecBooklet',
            },
            options: {
                admins: [],
                departments: [],
                departments_options: [],
                glyanec_booklet: [],
                format: [
                    {"value": "evro_booklet", "name": 'Євробуклет'},
                    {"value": "evro_2_booklet", "name": 'Буклет 2 євро'},
                    {"value": "a6", "name": 'A6'},
                    {"value": "a5", "name": 'A5'},
                    {"value": "a4", "name": 'A4'},
                    {"value": "a3", "name": 'A3'},
                ],
                circulation: [
                    {"value": "500", "name": '500'},
                    {"value": "1000", "name": '1000'},
                    {"value": "2000", "name": '2000'},
                    {"value": "2500", "name": '2500'},
                    {"value": "5000", "name": '5000'},
                    {"value": "10000", "name": '10000'},
                    {"value": "20000", "name": '20000'},
                ],
            },
            dropzoneOptions: {
                url: this.route('admin.vue.task.upload', this.task_id),
                thumbnailWidth: 150,
                maxFilesize: 500,
                maxFiles: 10,
                method: 'POST',
            },
            taskMedia: [],
            form_id: null
        };
    },
    mounted() {
        if (this.open) {
            this.prepareComponent();
        }
    },

    methods: {
        prepareComponent() {
            this.getSetting('glyanec_booklet')
            this.getAdmin(21)
            this.form_id = this.task_id
            if (this.form_id && this.unknown_task == false) {
                this.getTask(this.form_id)
            }

            if (this.form_id) {
                this.dropzoneOptions.url = this.route('admin.vue.task.upload', this.form_id)
            }
            this.priceСalculation()
        },

        getTask(id) {
            axios.get(this.route('admin.vue.task.info'), {
                params: {
                    id: id,
                }
            })
                .then(response => {

                    this.form_id = response.data.data.id
                    this.form.name = response.data.data.name
                    this.form.price = response.data.data.price
                    this.form.price_notes = response.data.data.price_notes
                    this.form.price_custom_check = response.data.data.price_custom_check
                    this.form.note = response.data.data.note
                    this.form.miscalculation = response.data.data.miscalculation ?? 0
                    this.form.performer_id = response.data.data.performer_id ?? 21

                    this.form.reminder_date = response.data.data.parameters.reminder_date ?? null
                    this.form.glyanec_format = response.data.data.parameters.glyanec_format ?? 'evro_booklet'
                    this.form.glyanec_material = response.data.data.parameters.glyanec_material ?? 0
                    this.form.glyanec_coating = response.data.data.parameters.glyanec_coating ?? 0
                    this.form.glyanec_color = response.data.data.parameters.glyanec_color ?? 0

                    this.form.circulation = response.data.data.parameters.circulation ?? 0
                    this.form.price_circulation = response.data.data.parameters.price_circulation ?? 0

                    this.taskMedia = response.data.data.files

                    this.getAdmin(response.data.data.performer_id)
                });

        },

        priceСalculation() {
            this.form.name = this.name
            this.form.department = this.department
            this.form.choice = this.choice
            axios.post(this.route('admin.vue.calculation.info'), this.form)
                .then(response => {
                    this.form.price_circulation = response.data.data.price
                    if (!this.form.price_custom_check) {
                        this.form.price = response.data.data.price
                    }
                    this.info = response.data.data.info

                    if(this.form_id){
                        this.dropzoneOptions.url = this.route('admin.vue.task.upload', this.form_id)
                    }
                });
        },

        getSetting(name) {
            axios.get(this.route('admin.vue.setting.info'), {
                params: {
                    system_name: name,
                }
            })
                .then(response => {
                    this.options[name] = response.data.data
                });

        },

        taskEdit(miscalculation = 1) {
            this.form.name = this.name
            this.form.department = this.department
            this.form.choice = this.choice
            this.form.date = this.date
            this.form.miscalculation = miscalculation
            axios.post(this.route('admin.vue.task.update'), Object.assign(this.form, {
                id: this.form_id,
                order_id: this.order_id
            }))
                .then(response => {

                    this.$emit('update:taskFormModal', false)

                    this.$bvToast.toast(this.trans.get('admin.general.text.success'), {
                        title: response.data.message,
                        variant: 'success',
                        solid: true
                    })
                })
                .catch(error => {
                    let errors = Object.values(error.response.data.errors);
                    errors = errors.flat();
                    this.errors = errors
                });
        },

        getAdmin(id) {
            if (id != null) {
                axios.get(this.route('admin.vue.admin.info'), {
                    params: {
                        id: id,
                    }
                })
                    .then(response => {
                        if (this.options.admins.find(item => item.id == id) === undefined) {
                            this.options.admins.push(response.data.data)
                        }
                    });
            }
        },
        onPerformer(search, loading) {
            loading(true);
            this.searchPerformer(loading, search, this);
        },
        searchPerformer: _.debounce((loading, search, vm) => {
            if (search) {
                axios.get(vm.route('admin.vue.admins.search'), {
                    params: {
                        q: search,
                    }
                })
                    .then(response => {
                        vm.options.admins = response.data.data
                        loading(false)
                    });
            }
            loading(false)
        }, 350),

        deleteMedia(id, index) {
            ConfirmDelete
            if (confirm(this.trans.get('admin.general.text.confirmDelete'))) {
                axios.post(this.route('admin.vue.media.delete'), {
                    id: id
                })
                    .then(response => {
                        this.taskMedia.splice(index, 1)
                    });
            }

        },
    },


    watch: {
        'open': function (val) {
            if (val) {
                this.prepareComponent();
                if (this.form_id && this.unknown_task == false) {
                    this.getTask(this.form_id)
                }
            }
        },
    }

}
</script>

<style scoped>

</style>
