<template>
    <div>
        <button @click="fileUploadFormModal=true" class="btn btn-success float-right mb-3" type="button">{{
                trans.get('admin.general.text.add')
            }}
        </button>

        <b-modal :title="trans.get('admin.pages.tasks..title.upload')" hide-footer id="upload-file" ref="upload-file" size="xl"
                 v-model="fileUploadFormModal">
            <div class="d-block text-center">
                <h3>{{ trans.get('admin.pages.tasks..title.upload') }}</h3>
            </div>

            <vue-dropzone :options="dropzoneOptions" id="fileUploadDropzone" ref="fileUploadDropzone"></vue-dropzone>

        </b-modal>

        <b-table :fields="fields" :items="itemsFiles" responsive="sm" striped>
            <template #cell(action)="row">
                <a :href="route('admin.vue.media.download') + '?id=' + row.item.id " :title="trans.get('admin.general.text.show')"
                   class="btn btn-primary btn-sm m-1" v-b-tooltip.hover><i class="fas fa-eye"></i></a>
                <button :title="trans.get('admin.general.text.destroy')" @click="deleteFile(row.item.id)"
                        class="btn btn-danger btn-sm m-1" v-b-tooltip.hover><i class="fas fa-trash"></i></button>
            </template>
        </b-table>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
    name: 'FilesTable',
    props: {
        order_id: {
            type: Number
        },
    },

    components: {
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: this.trans.get('admin.pages.tasks..col.name.title'),
                },
                {
                    key: 'action',
                    label: this.trans.get('admin.general.text.action'),
                },
            ],
            itemsFiles: [],
            fileUploadFormModal: false,
            dropzoneOptions: {
                url: '',
                thumbnailWidth: 150,
                maxFilesize: 500,
                maxFiles: 10,
                method: 'POST',
            }
        };
    },
    mounted() {
        this.prepareComponent();
    },

    methods: {
        prepareComponent() {
            this.getFiles();
            this.dropzoneOptions.url = this.route('admin.vue.order.files.upload', this.order_id)
        },
        deleteFile(id) {
            ConfirmDelete
            if (confirm(this.trans.get('admin.general.text.confirmDelete'))) {
                axios.post(this.route('admin.vue.media.delete'), {
                    id: id
                })
                    .then(response => {
                        this.getFiles()
                    });
            }

        },
        getFiles() {
            axios.get(route('admin.vue.order.files'), {
                params: {
                    id: this.order_id,
                }
            })
                .then(response => {
                    this.itemsFiles = response.data.data
                });
        },
    },
    watch: {
        'fileUploadFormModal': function (val) {
            if (!val) {
                this.getFiles()
            }
        },
    }
}
</script>
